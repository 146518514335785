<template>
    <div class="content">
       <div class="tool">
           <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-button type="primary" class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>

        <el-table :data='$store.state.PostDataList' border style="width: 100%;" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="post_name" label="岗亭名称"></el-table-column>
            <el-table-column align="center" prop="post_ip" label="岗亭电脑名"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width='400px' :append-to-body='true'>
            <el-form :model="postForm" :rules="rules" ref="postForm" label-width="100px" size='medium'>
                <el-form-item label="岗亭名称" prop="post_name">
                    <el-input v-model="postForm.post_name"></el-input>
                </el-form-item>
                <el-form-item label="岗亭电脑名" prop="post_ip">
                    <el-input v-model="postForm.post_ip"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      postForm: {
        post_id: 0,
        post_name: '',
        post_ip: ''
      },
      dialogVisible: false,
      title: '',
      rules: {
        post_name: [{ required: true, message: '请输入岗亭名称', trigger: 'blur' }],
        post_ip: [{ required: true, message: '请输入岗亭ip', trigger: 'blur' }]
      },
      isFinish: true
    }
  },
  created () {
    if(this.$store.state.PostDataList.length===0){
      this.$store.dispatch('getPostDataList')
    }
  },
  methods: {
    AddClick () {
      if(util.CheckUserAuth('1-5-1')){
        this.postForm.post_id = 0
        this.postForm.post_name = ''
        this.postForm.post_ip = ''
        this.title = '新增'
        this.dialogVisible = true
      }
    },
    EditClick (row) {
      if(util.CheckUserAuth('1-5-2')){
        var data = JSON.stringify(row)
        this.postForm = JSON.parse(data)
        this.title = '修改'
        this.dialogVisible = true
      }
    },
    DelClick (row) {
      if(util.CheckUserAuth('1-5-3')){
        this.$confirm('确定删除岗亭“' + row.post_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('post/del?id=' + row.post_id+'&name='+row.post_name).then(res => {
            if (res.rpStatus === 10000) { 
              this.$store.dispatch('getPostDataList')
             } else { this.$message.error(res.rpMsg) }
          })
        }).catch(e => e)
      }
    },
    submit () {
      this.$refs.postForm.validate((valid) => {
        if (valid) {
          util.Post('post/edit', this.postForm).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getPostDataList')
              this.dialogVisible = false
            } else { this.$message.error(util.ErrorMsg(res.RpStatus)) }
          })
        }
      })
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('post').then(res=>{
          this.isFinish=true
        })
      }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
